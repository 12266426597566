.ManageTrip-actions {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ManageTrip-actions .button-default {
    margin-top: 0;
}

.ManageTrip-actions .logout {
    background-color: #999;
    border-color: #999;
}
